// 设备管理
import request from '@/utils/util.js'

// 获取设备列表
export function getDeviceListAPI(data) {
	return request.get('/device/list', data)
}

// 新增、编辑 设备
export function saveDeviceAPI(data) {
	return request.post('/device/save', data)
}

// 删除 设备
export function deleteDeviceAPI(data) {
	return request.post('/device/delete', data)
}

// 获取床垫配置
export function getMattressConfigAPI(data) {
	return request.post('/sleepace/getalarmnotifyconfig', data)
}

// 修改床垫配置
export function updateMattressConfigAPI(data) {
	return request.post('/sleepace/updatealarmnotifyconfig', data)
}

// 获取床垫报警记录
export function getMattressAlarmRecordAPI(data) {
	return request.post('/sleepace/getalarmrecordlist', data)
}