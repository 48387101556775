<template>
  <div class="flex-col">
    <el-form ref="queryForm" :inline="true" :model="queryForm" size="small">
      <el-form-item label="类型" prop="type">
        <el-select v-model="queryForm.type" placeholder="请选择" clearable>
          <el-option label="床垫" :value="1"></el-option>
          <el-option label="SOS" :value="2"></el-option>
          <el-option label="雷达" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关键字" prop="type">
        <el-input v-model="queryForm.keyword" placeholder="设备名或编号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleSearch" type="primary">查询</el-button>
        <el-button @click="handleDeviceAdd" type="primary">新增设备</el-button>
        <el-button @click="handleAllMattressConfig" type="primary">所有床垫配置</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="deviceData" v-loading="loading" border stripe
      :header-cell-style="{background:'#E4E7ED',color: '#606266'}" size="small" max-height="500px" style="width: 100%;">
      <el-table-column label="#" type="index" width="60"></el-table-column>
      <el-table-column label="设备类型" v-slot="{ row }">{{ deviceTypeDict[row.type].name }}</el-table-column>
      <el-table-column label="设备编号" prop="device"></el-table-column>
      <el-table-column label="设备介绍" prop="ext"></el-table-column>
      <el-table-column label="用户名称" v-slot="{ row }">
        <span>{{ row.member_info ? row.member_info.realname : '' }}</span>
      </el-table-column>
      <el-table-column label="地点" prop="location"></el-table-column>
      <el-table-column label="地点示意图" v-slot="{ row }">
        <el-image v-if="row.location_photo" :src="row.location_photo" :preview-src-list="[row.location_photo]"
          fit="cover" style="width: 80px;height: 80px;"></el-image>
        <span v-else>无</span>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-link @click="handleDeviceEdit(row)" type="primary" class="margin-r-10">编辑</el-link>
          <el-popconfirm title="确定删除吗？" @confirm="handleDeviceDel(row.id)">
            <el-link slot="reference" type="danger" class="margin-r-10">删除</el-link>
          </el-popconfirm>
          <el-link v-if="row.type == 1" @click="handleMattressConfirg(row.id)" type="primary"
            class="margin-r-10">配置</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="margin-t-10 flex">
      <el-pagination background layout="sizes, prev, pager, next, total, jumper" :total="total"
        :page-sizes="[10, 20, 30]" :current-page.sync="queryForm.page" :page-size.sync="queryForm.page_size"
        @current-change="getDeviceList" @size-change="getDeviceList">
      </el-pagination>
      <el-button size="small">确定</el-button>
    </div>

    <el-dialog :title="deviceFormDialogTitle" :visible.sync="showDeviceFormDialog" width="700px"
      :before-close="closeDeviceFormDialog" :close-on-click-modal="false">
      <el-form ref="deviceForm" :model="deviceForm" :rules="rules" label-width="100px">
        <el-form-item label="设备编号" prop="device">
          <el-input v-model="deviceForm.device" placeholder="请在设备上找到设备编号,请确保编号正确"></el-input>
        </el-form-item>
        <el-form-item label="设备介绍" prop="ext">
          <el-input v-model="deviceForm.ext"></el-input>
        </el-form-item>
        <el-form-item label="设备类型" prop="type">
          <el-select v-model="deviceForm.type" placeholder="请选择" clearable class="el-input_inner--rewrite">
            <el-option label="床垫" :value="1"></el-option>
            <el-option label="SOS" :value="2"></el-option>
            <el-option label="雷达" :value="4"></el-option>
            <el-option label="燃气泄漏报警" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择用户" prop="member_id">
          <el-select v-model="deviceForm.member_id" :loading="userLoading" remote :remote-method="getUserRecords"
            filterable clearable placeholder="可输入姓名进行搜索" class="el-input_inner--rewrite">
            <el-option v-for="item in userRecords" :key="item.id" :label="item.realname" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地点名" prop="location">
          <el-input v-model="deviceForm.location"></el-input>
        </el-form-item>
        <el-form-item label="地点示意图" prop="location_photo">
          <el-upload ref="locationUpload" :action="UPLOAD_URL" :limit="1" :show-file-list="false"
            list-type="picture-card" :on-progress="handleProgress" :on-success="handleUploadLocationImgSuccess"
            :on-exceed="handleExceed">
            <img v-if="deviceForm.location_photo" :src="deviceForm.location_photo" style="width: 100%;height: 148px;">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <el-button @click="handleLocationImgRemove" size="small">删除示意图</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="closeDeviceFormDialog">取 消</el-button>
        <el-button :loading="submitting" @click="handleDeviceFormSubmit" type="primary">提 交</el-button>
      </span>
    </el-dialog>

    <!-- 配置床垫的弹窗 -->
    <el-dialog :title="mattressDialogTitle" :visible.sync="showMattressConfigDialog" width="900px"
      :before-close="closeMattressDialog">
      <el-form v-loading="configLoading" ref="mattressForm" :model="mattressForm" size="small" label-width="150px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="离床报警开关" prop="leftBedFlag">
              <el-switch v-model="mattressForm.leftBedFlag" :active-value="1" :inactive-value="0" active-color="#13ce66"
                inactive-color="#ff4949" active-text="开启" inactive-text="关闭">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="离床时长" prop="leftBedFlag">
              <el-select v-model="mattressForm.leftBedDuration" class="el-input_inner--rewrite">
                <el-option v-for="item in  leaveBedAlarmDurations" :key="item.val" :label="item.name"
                  :value="item.val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报警起始时间" prop="leftBedStartHour">
              <el-select v-model="mattressForm.leftBedStartHour" class="el-input_inner--rewrite">
                <el-option v-for="item in hours" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报警结束时间" prop="leftBedEndHour">
              <el-select v-model="mattressForm.leftBedEndHour" class="el-input_inner--rewrite">
                <el-option v-for="item in hours" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
          <el-col :span="8">
            <el-form-item label="心率过速报警开关" prop="heartRateFastFlag">
              <el-switch v-model="mattressForm.heartRateFastFlag" :active-value="1" :inactive-value="0"
                active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报警值" prop="maxHeartRate">
              <el-select v-model="mattressForm.maxHeartRate" class="el-input_inner--rewrite">
                <el-option v-for="item in maxHeartRateValues" :key="item.val" :label="item.text"
                  :value="item.val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="持续时长" prop="heartRateFastDuration">
              <el-select v-model="mattressForm.heartRateFastDuration" class="el-input_inner--rewrite">
                <el-option v-for="item in commonDurations" :key="item.val" :label="item.text"
                  :value="item.val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="心率过缓报警开关" prop="heartRateSlowFlag">
              <el-switch v-model="mattressForm.heartRateSlowFlag" :active-value="1" :inactive-value="0"
                active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报警值" prop="maxHeartRate">
              <el-select v-model="mattressForm.minHeartRate" class="el-input_inner--rewrite">
                <el-option v-for="item in minHeartRateValues" :key="item.val" :label="item.text"
                  :value="item.val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="持续时长" prop="heartRateSlowDuration">
              <el-select v-model="mattressForm.heartRateSlowDuration" class="el-input_inner--rewrite">
                <el-option v-for="item in minHeartRateDurations" :key="item.val" :label="item.text"
                  :value="item.val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="呼吸过速报警开关" prop="breathRateFastFlag">
              <el-switch v-model="mattressForm.breathRateFastFlag" :active-value="1" :inactive-value="0"
                active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报警值" prop="maxBreathRate">
              <el-select v-model="mattressForm.maxBreathRate" class="el-input_inner--rewrite">
                <el-option v-for="item in maxBreathValues" :key="item.val" :label="item.text"
                  :value="item.val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="持续时长" prop="breathRateFastDuration">
              <el-select v-model="mattressForm.breathRateFastDuration" class="el-input_inner--rewrite">
                <el-option v-for="item in minHeartRateDurations" :key="item.val" :label="item.text"
                  :value="item.val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="呼吸过缓报警开关" prop="breathRateSlowFlag">
              <el-switch v-model="mattressForm.breathRateSlowFlag" :active-value="1" :inactive-value="0"
                active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="报警值" prop="minBreathRate">
              <el-select v-model="mattressForm.minBreathRate" class="el-input_inner--rewrite">
                <el-option v-for="item in minBreathValues" :key="item.val" :label="item.text"
                  :value="item.val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="持续时长" prop="breathRateSlowDuration">
              <el-select v-model="mattressForm.breathRateSlowDuration" class="el-input_inner--rewrite">
                <el-option v-for="item in commonDurations" :key="item.val" :label="item.text"
                  :value="item.val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="呼吸暂停报警开关" prop="breathPauseFlag">
              <el-switch v-model="mattressForm.breathPauseFlag" :active-value="1" :inactive-value="0"
                active-color="#13ce66" inactive-color="#ff4949" active-text="开启" inactive-text="关闭">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="持续时长" prop="breathPauseDuration">
              <el-select v-model="mattressForm.breathPauseDuration" class="el-input_inner--rewrite">
                <el-option v-for="item in breathPauseDurations" :key="item.val" :label="item.text"
                  :value="item.val"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="closeMattressDialog">取 消</el-button>
        <el-button :loading="submitting" :disabled="configLoading" @click="hanldeMattressConfigSubmit" type="primary">确
          定</el-button>
      </span>
    </el-dialog>

    <!-- 查看床垫报警记录的弹窗 -->
    <el-dialog title="报警记录" :visible.sync="showMattressRecordDialog" width="500px">
      <span slot="footer">
        <el-button @click="showMattressRecordDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    getUserRecordDataAPI
  } from '@/api/user-record/record.js'
  import {
    getDeviceListAPI,
    deleteDeviceAPI,
    saveDeviceAPI,
    updateMattressConfigAPI,
    getMattressConfigAPI,
  } from '@/api/device.js'

  import {
    UPLOAD_URL,
  } from '@/utils/constant.js'

  export default {
    name: 'device',
    data() {
      return {
        UPLOAD_URL,
        deviceTypeDict: {
          1: {
            name: '床垫',
            value: 1
          },
          2: {
            name: 'SOS',
            value: 2
          },
          4: {
            name: '雷达',
            vlaue: 4
          },
          5: {
            name: '燃气泄露报警',
            value: 5
          }
        },
        submitting: false,
        fileUploading: false,

        userRecords: [],
        userLoading: false,

        queryForm: {
          type: '',
          keyword: '',
          page: 1,
          page_size: 10,
        },

        showDeviceFormDialog: false,
        deviceFormDialogTitle: '',
        deviceForm: {
          member_id: '',
          device: '',
          type: '',
          ext: '',
          location: '',
          location_photo: '',
        },
        rules: {
          device: [{
            required: true,
            message: '设备编号不能为空',
            trigger: 'blur'
          }],
          type: [{
            required: true,
            message: '设备类型不能为空',
            trigger: 'change'
          }],
        },

        deviceData: [],
        total: 1,
        loading: false,
        curRowId: '',

        // 床垫配置相关
        showMattressConfigDialog: false,
        mattressDialogTitle: '',
        mattressForm: {
          leftBedFlag: 1, // 0=关 1=开
          leftBedDuration: 3600,
          leftBedStartHour: '22:00',
          leftBedEndHour: '08:00',
          heartRateFastFlag: 1,
          heartRateFastDuration: 600,
          maxHeartRate: 120,
          heartRateSlowFlag: 1,
          heartRateSlowDuration: 1200,
          minHeartRate: 45,
          breathRateFastFlag: 1,
          breathRateFastDuration: 1200,
          maxBreathRate: 22,
          breathRateSlowFlag: 1,
          breathRateSlowDuration: 1200,
          minBreathRate: 10,
          breathPauseFlag: 1,
          breathPauseDuration: 60,
          // MemberDeviceId = 表格中行的id， 配置单个设备时需要
        },
        // 心率过速报警的心率值选项
        maxHeartRateValues: [{
          text: '120次/分钟',
          val: 120
        }, {
          text: '110次/分钟',
          val: 110
        }, {
          text: '115次/分钟',
          val: 115
        }, {
          text: '125次/分钟',
          val: 125
        }, {
          text: '130次/分钟',
          val: 130
        }],
        // 心率过缓报警的心率值选项
        minHeartRateValues: [{
          text: '40次/分钟',
          val: 40
        }, {
          text: '45次/分钟',
          val: 45
        }, {
          text: '55次/分钟',
          val: 55
        }],
        // 心率过速报警的时间选项
        commonDurations: [{
          text: '5分钟',
          val: 300
        }, {
          text: '10分钟',
          val: 600
        }, {
          text: '20分钟',
          val: 1200
        }, {
          text: '30分钟',
          val: 1800
        }, {
          text: '60分钟',
          val: 3600
        }],
        // 心率过缓、最大呼吸率持续时长的选项
        minHeartRateDurations: [{
          text: '15分钟',
          val: 900
        }, {
          text: '20分钟',
          val: 1200
        }, {
          text: '30分钟',
          val: 1800
        }, {
          text: '60分钟',
          val: 3600
        }],
        // 呼吸过速选项
        maxBreathValues: [{
          text: '22rpm',
          val: 22
        }, {
          text: '24rpm',
          val: 24
        }, {
          text: '26rpm',
          val: 26
        }, {
          text: '28rpm',
          val: 28
        }, {
          text: '30rpm',
          val: 30
        }],
        // 呼吸过缓选项
        minBreathValues: [{
          text: '9rpm',
          val: 9
        }, {
          text: '10rpm',
          val: 10
        }, {
          text: '12rpm',
          val: 12
        }, {
          text: '14rpm',
          val: 14
        }, {
          text: '16rpm',
          val: 16
        }],
        // 呼吸暂停持续时长的选项
        breathPauseDurations: [{
          text: '15秒',
          val: 15,
        }, {
          text: '30秒',
          val: 30,
        }, {
          text: '45秒',
          val: 45
        }, {
          text: '1分钟',
          val: 60
        }],
        // 离床报警时间间隔
        leaveBedAlarmDurations: [{
          name: '立即',
          val: 0
        }, {
          name: '半小时',
          val: 1800
        }, {
          name: '1小时',
          val: 3600
        }, {
          name: '2小时',
          val: 7200
        }],
        hours: [],
        configLoading: false,
        // 床垫报警记录相关
        showMattressRecordDialog: false,
        mattressRecords: []
      }
    },
    created() {
      this.hours = this.generateHours()
      this.getDeviceList()
      this.getUserRecords()
    },
    methods: {
      handleSearch() {
        this.queryForm.page = 1
        this.getDeviceList()
      },
      handleDeviceAdd() {
        this.showDeviceFormDialog = true
        this.deviceFormDialogTitle = '新增设备'
      },
      handleDeviceEdit(row) {
        this.showDeviceFormDialog = true
        this.deviceFormDialogTitle = '编辑设备'
        this.curRowId = row.id
        const copyRow = JSON.parse(JSON.stringify(row))
        // 回显老人
        if (row.member_info !== null) this.userRecords = [row.member_info]
        // 回显
        for (let key in this.deviceForm) {
          this.deviceForm[key] = copyRow[key]
        }
        if (!this.deviceForm.member_id) {
          this.deviceForm.member_id = ''
        }
      },
      closeDeviceFormDialog() {
        this.$refs.deviceForm.resetFields()
        this.showDeviceFormDialog = false
      },
      // 上传中
      handleProgress() {
        this.fileUploading = true
      },
      // 上传地点示意图成功
      handleUploadLocationImgSuccess(res) {
        this.deviceForm.location_photo = res.data.url
        this.fileUploading = false
      },
      handleLocationImgRemove() {
        this.deviceForm.location_photo = ''
        this.$refs.locationUpload.clearFiles()
      },
      handleExceed() {
        this.$message.error('请先删除现有的示意图后再进行尝试')
      },
      handleDeviceDel(id) {
        deleteDeviceAPI({
          id
        }).then(() => {
          this.$message.success('删除成功')
          this.getDeviceList()
        })
      },
      handleDeviceFormSubmit() {
        if (this.fileUploading) {
          return this.$message.warning('地点示意图正在上传中，请稍后重试')
        }

        this.$refs.deviceForm.validate(isValid => {
          if (!isValid) return
          if (this.deviceForm.member_id == '') {
            this.deviceForm.member_id = 0
          }
          this.submitting = true
          const copyForm = Object.assign({}, this.deviceForm)

          if (this.deviceFormDialogTitle == '编辑设备') {
            copyForm.id = this.curRowId
          }
          saveDeviceAPI(copyForm).then(() => {
            this.$message.success('保存成功')
            this.closeDeviceFormDialog()
            this.getDeviceList()
          }).finally(() => this.submitting = false)
        })
      },
      // 所有配置床垫按钮
      handleAllMattressConfig() {
        this.showMattressConfigDialog = true
        this.mattressDialogTitle = '所有床垫配置'
      },
      // 床垫配置按钮
      async handleMattressConfirg(rowId) {
        this.showMattressConfigDialog = true
        this.configLoading = true
        this.mattressDialogTitle = '单个床垫配置'
        this.curRowId = rowId
        // 根据 id 获取床垫配置
        const resConfig = await getMattressConfigAPI({
          MemberDeviceId: rowId
        }).then(res => {
          this.configLoading = false
          const resData = res.data.data
          resData.leftBedStartHour = this.getStrHour(resData.leftBedStartHour)
          resData.leftBedEndHour = this.getStrHour(resData.leftBedEndHour)
          return resData
        })
        // 回显床垫配置
        for (let key in this.mattressForm) {
          this.mattressForm[key] = resConfig[key]
        }
      },
      closeMattressDialog() {
        this.$refs.mattressForm.resetFields()
        this.showMattressConfigDialog = false
      },
      hanldeMattressConfigSubmit() {
        this.submitting = true
        const copyForm = Object.assign({}, this.mattressForm)
        copyForm.leftBedStartHour = this.getIntHour(copyForm.leftBedStartHour)
        copyForm.leftBedEndHour = this.getIntHour(copyForm.leftBedEndHour)

        if (this.mattressDialogTitle == '单个床垫配置') {
          copyForm.MemberDeviceId = this.curRowId
        }
        updateMattressConfigAPI(copyForm).then(() => {
          this.$message.success('保存成功')
          this.closeMattressDialog()
        }).finally(() => this.submitting = false)
      },
      // 获取整数的小时，val = 'xx:xx'，取冒号左边的值，然后转为int
      getIntHour(val) {
        return parseInt(val.split(':')[0])
      },
      // 整数的小时转换为'xx:xx'，val = 整数的小时
      getStrHour(val) {
        return val > 10 ? `${val}:00` : `0${val}:00`
      },
      // 生成小时数，下拉框需要用到
      generateHours() {
        const limit = 23
        let hours = []

        for (let i = 0; i <= limit; i++) {
          let hour = ''
          if (i < 10) hour = `0${i}:00`
          else hour = `${i}:00`
          hours.push(hour)
        }
        return hours
      },

      getUserRecords(keyword) {
        this.userLoading = true

        setTimeout(() => {
          getUserRecordDataAPI({
            keyword,
            page: 1,
          }).then(res => {
            this.userRecords = res.data
            this.userLoading = false
          })
        }, 300)
      },
      getDeviceList() {
        this.loading = true

        getDeviceListAPI(this.queryForm).then(res => {
          this.deviceData = res.data
          this.total = res.total
          this.loading = false
        })
      },
    }
  }
</script>

<style>
</style>